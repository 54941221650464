<template>
  <Autenticacao />
</template>

<script>
import Autenticacao from "@/components/users/Autenticacao.vue";

export default {
  components: {
    Autenticacao
  }
};
</script>
